import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import {
  Section,
  BreadCrumb,
  Background,
  SectionPageTitle,
  Badge,
  Container,
} from "../components/SectionTags"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import Bg from "../components/Image/IndustryHeroBg"
import EmergencyEstimateCTA from "../components/CallToAction/EmergencyEstimateCTA"
import FadeDrop from "../components/FadeDrop"
import ContentRightForm from "../components/ContentRightForm"
import BreakpointUp from "../components/Media/BreakpointUp"

const _ = require("lodash")

const MainPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  justify-content: space-between;
`
const LeftPanel = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px;
  ${BreakpointUp.lg`  
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  `}
`
const RightPanel = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px;
  ${BreakpointUp.lg`  
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
    margin-top:60px;
  `}
  ${BreakpointUp.xl`
    margin-top:150px;
  `}
`

const CategoryCard = styled.div`
  width: 100%;
  background-color: #edf8ff;
  border: 1px solid #d6ecff;
  border-radius: 8px;
  box-shadow: 0 15px 35px rgba(0, 89, 178, 0.1);
  padding: 30px 20px;
  margin-bottom: 30px;
  ${BreakpointUp.md`
    padding:40px;
  `}
  ${BreakpointUp.lg`    
    padding:40px 50px;
  `}
`
const CategoryCardTitle = styled.div`
  display: block;
  font-family: 'UniNeueBold', sans-serif;
  font-weight: 800;
  margin-bottom: 10px;
  color: #000;
  font-size: 20px;
  line-height: 30px;
  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 32px;
  }
  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 34px;
  }
`
const FaqWrap = styled.div`
  margin-bottom: 30px;
`
const ListBadge = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
`

class Faq extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentCategory: "General",
    }
    this.selectCategory = this.selectCategory.bind(this)
  }
  selectCategory(data) {
    this.setState({
      currentCategory: data,
    })
  }
  render() {
    const pageData = this.props.data.contentfulFaqsPage
    const faqs = this.props.data.allContentfulFaq
    let categories = ["General"]
    faqs.edges.forEach(function (element, index) {
      categories.push(element.node.category.serviceName)
    })
    categories = _.uniq(categories)

    const { currentCategory } = this.state
    return (
      <Layout>
        <Seo title={pageData.metaTitle} description={pageData.metaDescription.metaDescription} />
        <div className="sticky-wrapper sticky">
          <Header />
        </div>
        <Section xpt="120px" xpb="80px" mpt="120px" mpb="90px" pt="90px" pb="60px">
          <Background right="0" height="inherit" ts="rotateY(180deg)"><Bg /></Background>
          <Container maxWidth="1120px">
            <MainPanel>
              <LeftPanel>
                <BreadCrumb>
                  <Link to="/"><span>Home</span></Link> FAQ
                </BreadCrumb>
                <SectionPageTitle mb="0">{pageData.title}</SectionPageTitle>
                <FaqWrap>
                  {faqs.edges.map((item, i) => {
                    if (currentCategory === "General") {
                      return <FadeDrop data={item} key={i} />
                    } else {
                      if (item.node.category.serviceName === currentCategory) {
                        return <FadeDrop data={item} key={i} />
                      }
                    }
                  })}
                </FaqWrap>
              </LeftPanel>
              <RightPanel>
                <div className="isSticky">
                  <CategoryCard>
                    <CategoryCardTitle>Categories</CategoryCardTitle>
                    <ListBadge>
                      {categories.map((item, i) => {
                        return (
                          <Badge
                            key={i}
                            mb="10px"
                            bg={
                              item === currentCategory ? "#FAB702" : "#FFFFFF"
                            }
                            color={
                              item === currentCategory
                                ? "#FFFFFF"
                                : "rgba(0,0,0,.6)"
                            }
                            border={
                              item === currentCategory
                                ? "1px solid #FAB702"
                                : "1px solid #C3E3FF"
                            }
                            onClick={() => this.selectCategory(item)}
                          >
                            {item}
                          </Badge>
                        )
                      })}
                    </ListBadge>
                  </CategoryCard>
                  <ContentRightForm />
                </div>
              </RightPanel>
            </MainPanel>
          </Container>
        </Section>
        <EmergencyEstimateCTA
          leftData={pageData.emergencyCta}
          rightData={pageData.estimateCta}
        />
      </Layout>
    )
  }
}

export default Faq

export const pageQuery = graphql`
  query FaqsPageQuery {
    contentfulFaqsPage {
      metaTitle
      metaDescription {
        metaDescription
      }
      title
      emergencyCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
      estimateCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
    }
    allContentfulFaq {
      edges {
        node {
          question
          answer {
            childMarkdownRemark {
              html
            }
          }
          category {
            serviceName
          }
        }
      }
    }
  }
`
